<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Rider Assigned Order List</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <router-link to="/hub-rider-assign" class="btn btn-success float-right mr-2" ><i class="fas fa-list"></i> Rider Assign</router-link>
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form>
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="title">Rider</label>
                                                <v-select name="hub_id"
                                                    v-model="search.rider_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= riderList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="authUser.role_id != 7">
                                            <div class="form-group">
                                                <label for="title">{{ $t('globalTrans.hub') }}</label>
                                                <v-select name="hub_id"
                                                    v-model="search.hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="date">Date</label>
                                                <input type="date" id="date" v-model="search.date" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="date">Total Pending(Pcs)</label>
                                                <input type="text" class="form-control" v-model="totalPending" disabled="true"/>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="date">Total Assigned(Pcs)</label>
                                                <input type="text" class="form-control" v-model="totalAssigned" disabled="true"/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Rider Name</th>
                                                    <th>Rider Mobile</th>
                                                    <th>Total Order</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Rider Name</th>
                                                    <th>Rider Mobile</th>
                                                    <th>Total Order</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ index+1 }}</td>
                                                    <td>{{ item.rider_name }}</td>
                                                    <td>{{ item.rider_mobile }}</td>
                                                    <td>{{ item.total_order }}</td>
                                                    <td style="text-align:center;">
                                                        <button class="btn btn-primary btn-sm mr-1" @click="showOrder(item.rider_id, item.status, item.hub_id)" title="Show Order"> <i class="fa fa-eye"></i> </button>  
                                                        <button class="btn btn-warning btn-sm mr-1"  @click="downloadPdf(item.rider_id, item.status)" title="PDF"><i class="fa fa-download" aria-hidden="true"></i></button>                                                         
                                                        <a :href="$baseUrl + 'excel-download/assigned-order?rider_id='+ item.rider_id +'&status='+ item.status + '&date='+ search.date" class="btn btn-success btn-sm" target="_blank" title="Excel"><i class="fa fa-download" aria-hidden="true"></i></a>               
                                                        <button v-if="item.status == 7" style="margin-left:30px;" class="btn btn-info btn-sm"  @click="onWay(item.rider_id)" title="On Way"><i class="fa fa-truck" aria-hidden="true"></i></button>                                             
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>           
                                    </div>             
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
import config from '@/config'
import moment from 'moment'
import ExportPdf from './AssignedOrder'

export default {
    name:'Hub',
    data(){
        return {
            loader: false,
            search: {
                rider_id: '',
                hub_id: '',
                date: moment().format('YYYY-MM-DD')
            },
            itemId: 0,
            errors: [],
            createModal: false,
            editModal: false,
            deleteModal: false,
            key: '',
            totalPending: 0,
            totalAssigned: 0,
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created() {
        this.loadData()
        this.loadSummary()
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadData()
                this.loadSummary()
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        },
        hubList () {
            return this.$store.state.commonObj.hubList
        },
        authUser () {
            return this.$store.state.authUser
        },
        riderList () {
            if (this.authUser.role_id == 7) {
                return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
            }
            
            return this.$store.state.commonObj.riderList
        }
    },
    methods:{
        searchData () {
            this.loadData()
            this.loadSummary()
        },
        async loadData() {     
            this.loader = true 
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id, page: this.pagination.currentPage, per_page: this.pagination.perPage }) : this.search 
            const response = await config.getData('/hub/rider-assigned/list', params)
            this.loader = false
            if (response.success){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        async loadSummary() {     
            this.loader = true 
            // const params = { hub_id: this.authUser.hub_id, date: this.search.date }
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search
            const response = await config.getData('/hub/rider-assigned/summary', params)
            this.loader = false
            if (response.success){
                this.totalAssigned = response.summary.total_assigned
                this.totalPending  = response.summary.total_pending
            } else {
                this.totalAssigned = this.totalPending  = 0
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        getRiderDetails (riderId) {
            const rider = this.$store.state.commonObj.riderList.find(el => el.id == riderId)
            return rider ? (rider.text + ', '+ rider.mobile) : ''
        },
        async downloadPdf(riderId, status){
            this.loader = true
            const params = { date: this.search.date, hub_id: this.authUser.hub_id, status: status, rider_id: riderId}
            const response = await config.getData(`/hub/rider-assigned/details-list`, params)
            this.loader = false
            if (response.success) {
                const riderDetails = this.getRiderDetails(riderId)
                this.pdfDownload(response.data, riderDetails, response.total)   
            } else {                
                this.$toast.error('Sorry, something went wrong')  
            }
        },
        async onWay(riderId){
            this.loader = true
            const params = { rider_id: riderId}
            const response = await config.postData(`/hub/rider-assigned/on-way`, params)
            this.loader = false
            if (response.success) {
                this.loadData()
                this.$toast.success({
                    title: 'Success',
                    message: response.message,
                    color: '#218838'
                })
            } else {                
                 this.$toast.error({
                    title: 'Error',
                    message: response.message,
                    color: '#dc3545'
                })
            }            
        },
        pdfDownload(datas, riderDetails, totalValue) {
            const hub = this.hubList.find(el => el.id == this.authUser.hub_id)
            const hubName = hub ? hub.text : ''
            const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
            ExportPdf.returnPdfDetails(base64Logo, datas, riderDetails, totalValue, hubName)
        },
        getBase64Logo (logo) {
            var canvas = document.createElement('canvas')
            canvas.width = logo.width
            canvas.height = logo.height
            var ctx = canvas.getContext('2d')
            ctx.drawImage(logo, 0, 0)
            var dataURL = canvas.toDataURL('image/png')
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
        },
        showOrder (riderId, status, hubId) {
            this.$router.push({ name: 'hub.rider_assigned_order_details', query: { rider_id: riderId, status: status, hub_id: hubId, date: this.search.date } })
        }
    }
}
</script>